<template>
  <base-auth-view>
    <portal-target name="notificationNominasAlertPlace" />

    <FormulateForm
      v-if="!passwordUpdated"
      v-model="formValues"
      @submit="submit"
      :errors="errors"
      #default="{ isLoading, isValid }"
    >
      <!--(Nueva contraseña)-->
      <FormulateInput
        name="password"
        label="Nueva contraseña"
        type="password"
        placeholder="Ingresa una nueva contraseña"
        validation="required|min:10,length|max:32,length|noRepeatingCharacters|notConsecutiveNumbers|atLeastOneMayus|atLeastOneSymbol|atLeastOneNumber"
        :validation-rules="$getPasswordValidationRules"
        :validation-messages="$getPasswordValidationMessages"
      />

      <!--(Confirmar contraseña)-->
      <FormulateInput
        name="password_confirmation"
        label="Repetir contraseña"
        type="password"
        placeholder="Repite la contraseña"
        validation="bail|required|confirm:password"
        :validation-messages="{
          required: 'Debes confirmar la contraseña',
          confirm: 'Las contraseñas no coinciden'
        }"
      />

      <FormulateInput
        type="submit"
        input-class="btn btn-primary btn-block"
        :disabled="isLoading || !isValid"
        :label="isLoading ? 'Validando...' : 'Cambiar contraseña'"
      />
    </FormulateForm>
    <!--(Login link)-->
    <div v-else class="my-2 w-100 text-center">
      <router-link class="btn btn-primary btn-block small" :to="{ name: 'Login' }">
        Iniciar sesión
      </router-link>
    </div>
  </base-auth-view>
</template>

<script>
import BaseAuthView from '@/views/Layouts/BaseAuthView'

export default {
  name: 'PasswordConfirmView',

  components: {
    BaseAuthView
  },

  props: {
    token: {
      type: String,
      required: true
    }
  },

  created () {
    this.email = this.$route.query.email
  },

  data () {
    return {
      formValues: {
        password: '',
        password_confirmation: ''
      },
      errors: {},
      email: null,
      passwordUpdated: false
    }
  },

  methods: {
    async submit (data) {
      data.token = this.token
      data.email = this.email

      const getval = await this.$store.dispatch('authModule/resetPassword', data)

      if (getval.data && getval.data.errors) {
        this.errors = getval.data.errors
      }

      this.$alert(getval, 'Cambio de contraseña', 'notificationNominasAlertPlace', {
        status: getval.error ? 'danger' : 'success',
        closeable: false
      })

      if (getval.error) return

      this.passwordUpdated = true
    }
  }
}
</script>
